/*------------------------------------------------------------
footer
------------------------------------------------------------*/

.c-footer {
    font-family: 'Noto Sans JP', sans-serif;
    &__list {
        padding: 0 20px;
        max-width: 1220px;
        max-width: 1220px;
        margin: 40px auto 28px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        font-size: 1.2rem;
        color: white;
    }
}