/* layout
------------------------------------------------------------*/

body {
    overflow-x: hidden;
    font-family: 'Shippori Mincho', serif;
    font-size: 1.4rem;
    line-height: 1.4;
    background-color: black;
    color: white;
}

//-------------------------------------------------------
main {}

section {}

//-------------------------------------------------------
.l-container {
    width: $baseWidth;
    margin: 0 auto;
}

//-------------------------------------------------------
.c-mainvisual {}