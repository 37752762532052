/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/

.p-top {
    //-------------------------------------------------------
    &1 {
        margin-bottom: 72px;
        margin-top: 30px;
        padding: 0 50px;
        @media only screen and (max-width: 767px) {
            padding: 0 20px;
        }
        &__image {
            position: relative;
            height: 100vh;
            background-image: url(../img/bg-index.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            @media only screen and (max-width: 767px) {
                height: 583px;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
        &__star {
            position: absolute;
            left: 50%;
            top: 46%;
            max-width: 328px;
            transform: translate(-50%, -50%);
        }
    }
    //-------------------------------------------------------
    &2 {}
    //-------------------------------------------------------
    &3 {}
    //-------------------------------------------------------
    &4 {}
}

.p {
    &-top2 {}
    &-top2__list {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        max-width: 641.3px;
        margin: 0 auto 202px;
        color: white;
        @media only screen and (max-width: 767px) {
            padding: 0 20px;
            justify-content: center;
            flex-wrap: wrap;
        }
    }
    &-top2__left {
        margin-top: 20px;
        width: 248.7px;
        @media only screen and (max-width: 767px) {
            margin: 40px 20px;
        }
        @media only screen and (max-width: 516px) {
            margin: 40px 0;
        }
    }
    &-top2__phone {
        line-height: 1.2;
        span:first-child {
            display: block;
            font-size: 2.248rem;
        }
        span:last-child {
            font-size: 3.887rem;
        }
    }
    &-top2__line {
        margin-top: 58px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
    }
    &-top2__image {
        width: 77.4px;
    }
    &-top2__qr {
        width: 101.2px;
    }
    &-top2__info {
        font-size: 2.496rem;
    }
    &-top2__right {
        width: 282.7px;
        @media only screen and (max-width: 767px) {
            margin: 40px 20px;
        }
        @media only screen and (max-width: 516px) {
            margin: 40px 0;
        }
    }
}

.p-loader {
    left: 0;
    top: 0;
    background: black;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    color: #D9B875;
    font-size: 90px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 72px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 2.5s infinite ease, round 2.5s infinite ease;
    animation: load6 2.5s infinite ease, round 2.5s infinite ease;
}

@-webkit-keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.header-menu {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 19.6vh 157px 25px;
    color: #fff;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.header-menu-is-active {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.c-header__meta {
    z-index: 1001;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    display: -webkit-box;
    display: flex;
    position: absolute;
    top: 12px;
    right: 2.6%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.p-burger {
    z-index: 5;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin-top: 14px;
    display: none;
    @media only screen and (max-width: 1024px) {
        display: block;
    }
}

.p-burger .p-burger__container {
    justify-content: space-around;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 30px;
    height: 40px;
    position: relative;
    cursor: pointer;
}

.p-burger .p-burger__container {
    width: 44px;
    display: -webkit-box;
    display: flex;
}

.p-burger .c-line {
    width: 44px;
    height: 2px;
    background-color: white;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.p-burger__text {
    font-weight: bolder;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.p-burger.is-active .c-line {
    position: absolute;
}

.p-burger .c-line.is-active {
    background-color: white;
}

.p-burger.is-active .c-line:nth-child(1) {
    -webkit-transform: rotate( 45deg);
    transform: rotate( 45deg);
}

.p-burger.is-active .c-line:nth-child(2) {
    display: none;
}

.p-burger.is-active .c-line:nth-child(3) {
    -webkit-transform: rotate( -45deg);
    transform: rotate( -45deg);
}

.p-burger__text.is-active {
    color: white;
}

.c-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: white;
    top: 0;
    left: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: 0.4s -webkit-transform ease-in-out;
    transition: 0.4s -webkit-transform ease-in-out;
    transition: 0.4s transform ease-in-out;
    transition: 0.4s transform ease-in-out, 0.4s -webkit-transform ease-in-out;
}

.c-menu.is-open {
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.c-header .l-container {
    height: 578px;
    padding-top: 32px;
}

.c-menu__logo {
    z-index: 5;
    position: absolute;
    top: 32px;
    left: 20px;
}

.c-menu ul {
    position: absolute;
    width: 70%;
    top: 30%;
    left: 43%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.c-menu li {
    margin-bottom: 20px;
    font-size: 2rem;
    color: white;
}

body.is-hidden {
    touch-action: none;
    -ms-touch-action: none;
    overflow-y: hidden;
}

.c-header__meta {
    display: none;
}

@media only screen and (max-width: 767px) {
    .c-header__meta {
        display: block;
    }
}

@media only screen and (max-width: 516px) {
    .p-burger .p-burger__container {
        width: 35px;
    }
    .p-burger .c-line {
        width: 35px;
    }
    .p-burger__text {
        font-size: 1.1rem;
    }
}