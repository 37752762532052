@charset "UTF-8";
/*------------------------------------------------------------
	Reset
------------------------------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: 100%;
}

body, table,
input, textarea, select, option,
h1, h2, h3, h4, h5, h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

table,
input, textarea, select, option {
  line-height: 1.1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

img {
  vertical-align: top;
}

a,
a img {
  transition: 0.3s ease-in-out;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:hover img {
  opacity: 0.80;
  filter: alpha(opacity=80);
  -ms-filter: "alpha(opacity=80)";
}

img {
  max-width: 100%;
}

.sp-only {
  display: none;
}

@media only screen and (max-width: 767px) {
  .pc-only {
    display: none;
  }
  .sp-only {
    display: block;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Layout .l-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/* Header
------------------------------------------------------------*/
/* Main menu
------------------------------------------------------------*/
.c-header {
  color: white;
}

.c-header li {
  font-weight: 600;
}

.c-header__list {
  padding: 0 20px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 19px auto;
  max-width: 1118px;
}

@media only screen and (max-width: 1024px) {
  .c-header__list {
    align-items: center;
  }
}

@media only screen and (max-width: 1024px) {
  .c-header__info {
    display: none;
  }
}

.c-header__info h2 {
  font-size: 2.8rem;
}

.c-header__info span {
  font-size: 1.5rem;
}

.c-header__info span p {
  font-size: 2.3rem;
}

.c-header__logo {
  margin-top: 20px;
  width: 102px;
}

@media only screen and (max-width: 1024px) {
  .c-header__logo {
    margin-top: 0;
  }
}

.c-header__recruit {
  width: 248px;
  margin-top: 30px;
}

@media only screen and (max-width: 1024px) {
  .c-header__recruit {
    margin-top: 0;
  }
}

@media only screen and (max-width: 516px) {
  .c-header__recruit {
    display: none;
  }
}

.c-header__menu {
  padding: 0 20px;
  max-width: 947px;
  margin: 35px auto 15px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 1024px) {
  .c-header__menu {
    display: none;
  }
}

.c-header__menu li {
  padding: 0 3px;
  position: relative;
}

.c-header__menu span:after {
  z-index: 999;
  content: "";
  position: absolute;
  top: 140%;
  left: 0;
  width: 100%;
  height: 7px;
  background: #DAB975;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.c-header__menu li:hover span:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.c-header__border {
  height: 1px;
  width: 100%;
  background-color: #DAB975;
}

.header-menu__link {
  position: absolute;
  width: 70%;
  top: 20%;
  left: 43%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.header-menu__link li {
  margin-bottom: 30px;
  font-size: 2rem;
  color: white;
}

/*------------------------------------------------------------
footer
------------------------------------------------------------*/
.c-footer {
  font-family: 'Noto Sans JP', sans-serif;
}

.c-footer__list {
  padding: 0 20px;
  max-width: 1220px;
  max-width: 1220px;
  margin: 40px auto 28px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 1.2rem;
  color: white;
}

/*------------------------------------------------------------
sidebar
------------------------------------------------------------*/
/* Breadcrumb
------------------------------------------------------------*/
/* layout
------------------------------------------------------------*/
body {
  overflow-x: hidden;
  font-family: 'Shippori Mincho', serif;
  font-size: 1.4rem;
  line-height: 1.4;
  background-color: black;
  color: white;
}

.l-container {
  width: 1200px;
  margin: 0 auto;
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Component .c-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
btn
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
title
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
icon
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
form
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
text
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
navi
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
img
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
list
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
table
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
line
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
other
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Project .p-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-top1 {
  margin-bottom: 72px;
  margin-top: 30px;
  padding: 0 50px;
}

@media only screen and (max-width: 767px) {
  .p-top1 {
    padding: 0 20px;
  }
}

.p-top1__image {
  position: relative;
  height: 100vh;
  background-image: url(../img/bg-index.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 767px) {
  .p-top1__image {
    height: 583px;
  }
}

.p-top1__image img {
  width: 100%;
  height: 100%;
}

.p-top1__star {
  position: absolute;
  left: 50%;
  top: 46%;
  max-width: 328px;
  transform: translate(-50%, -50%);
}

.p-top2__list {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  max-width: 641.3px;
  margin: 0 auto 202px;
  color: white;
}

@media only screen and (max-width: 767px) {
  .p-top2__list {
    padding: 0 20px;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.p-top2__left {
  margin-top: 20px;
  width: 248.7px;
}

@media only screen and (max-width: 767px) {
  .p-top2__left {
    margin: 40px 20px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top2__left {
    margin: 40px 0;
  }
}

.p-top2__phone {
  line-height: 1.2;
}

.p-top2__phone span:first-child {
  display: block;
  font-size: 2.248rem;
}

.p-top2__phone span:last-child {
  font-size: 3.887rem;
}

.p-top2__line {
  margin-top: 58px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.p-top2__image {
  width: 77.4px;
}

.p-top2__qr {
  width: 101.2px;
}

.p-top2__info {
  font-size: 2.496rem;
}

.p-top2__right {
  width: 282.7px;
}

@media only screen and (max-width: 767px) {
  .p-top2__right {
    margin: 40px 20px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top2__right {
    margin: 40px 0;
  }
}

.p-loader {
  left: 0;
  top: 0;
  background: black;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  color: #D9B875;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 2.5s infinite ease, round 2.5s infinite ease;
  animation: load6 2.5s infinite ease, round 2.5s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.header-menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 19.6vh 157px 25px;
  color: #fff;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.header-menu-is-active {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.c-header__meta {
  z-index: 1001;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  display: -webkit-box;
  display: flex;
  position: absolute;
  top: 12px;
  right: 2.6%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.p-burger {
  z-index: 5;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-top: 14px;
  display: none;
}

@media only screen and (max-width: 1024px) {
  .p-burger {
    display: block;
  }
}

.p-burger .p-burger__container {
  justify-content: space-around;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 30px;
  height: 40px;
  position: relative;
  cursor: pointer;
}

.p-burger .p-burger__container {
  width: 44px;
  display: -webkit-box;
  display: flex;
}

.p-burger .c-line {
  width: 44px;
  height: 2px;
  background-color: white;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.p-burger__text {
  font-weight: bolder;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.p-burger.is-active .c-line {
  position: absolute;
}

.p-burger .c-line.is-active {
  background-color: white;
}

.p-burger.is-active .c-line:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.p-burger.is-active .c-line:nth-child(2) {
  display: none;
}

.p-burger.is-active .c-line:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.p-burger__text.is-active {
  color: white;
}

.c-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
  top: 0;
  left: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition: 0.4s -webkit-transform ease-in-out;
  transition: 0.4s -webkit-transform ease-in-out;
  transition: 0.4s transform ease-in-out;
  transition: 0.4s transform ease-in-out, 0.4s -webkit-transform ease-in-out;
}

.c-menu.is-open {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.c-header .l-container {
  height: 578px;
  padding-top: 32px;
}

.c-menu__logo {
  z-index: 5;
  position: absolute;
  top: 32px;
  left: 20px;
}

.c-menu ul {
  position: absolute;
  width: 70%;
  top: 30%;
  left: 43%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.c-menu li {
  margin-bottom: 20px;
  font-size: 2rem;
  color: white;
}

body.is-hidden {
  touch-action: none;
  -ms-touch-action: none;
  overflow-y: hidden;
}

.c-header__meta {
  display: none;
}

@media only screen and (max-width: 767px) {
  .c-header__meta {
    display: block;
  }
}

@media only screen and (max-width: 516px) {
  .p-burger .p-burger__container {
    width: 35px;
  }
  .p-burger .c-line {
    width: 35px;
  }
  .p-burger__text {
    font-size: 1.1rem;
  }
}

/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-about {
  position: relative;
  margin-bottom: 268px;
  margin-top: 44px;
  color: white;
}

@media only screen and (max-width: 767px) {
  .p-about {
    margin-bottom: 100px;
  }
}

.p-about__bgr {
  padding-left: 28%;
}

.p-about__meta {
  top: 0;
  max-width: 1118px;
  height: 100%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
}

.p-about__image {
  position: relative;
  height: 100vh;
  background-image: url(../img/bg-about.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 767px) {
  .p-about__image {
    height: 583px;
  }
}

.p-about__image img {
  width: 100%;
  height: 100%;
}

.p-about__content {
  position: absolute;
  height: 100vh;
  background-image: url(../img/shadow-about.png);
  background-size: cover;
  top: 14px;
  left: 34px;
  max-width: 485px;
  width: 100%;
}

@media only screen and (max-width: 516px) {
  .p-about__content {
    left: 20px;
    line-height: 2;
    height: 583px;
    width: 90%;
  }
}

.p-about__text {
  margin-top: 46px;
}

.p-about__info {
  display: flex;
  margin-bottom: 38px;
}

.p-about__info span:first-child {
  width: 107px;
  display: inline-block;
}

@media only screen and (max-width: 516px) {
  .p-about__info span:last-child {
    width: 60%;
  }
}

.p-about__sp {
  display: none;
}

@media only screen and (max-width: 516px) {
  .p-about__sp {
    display: block;
  }
}

@media only screen and (max-width: 516px) {
  .p-about__pc {
    display: none;
  }
}

.c-title span:first-child {
  font-family: 'Tangerine', cursive;
  font-size: 8.2rem;
  font-style: italic;
  color: #C6BE77;
  margin-right: 35px;
  font-weight: 700;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .c-title span:first-child {
    font-size: 6.2rem;
  }
}

@media only screen and (max-width: 516px) {
  .c-title span:first-child {
    font-size: 5.2rem;
    display: block;
    margin-right: 0;
  }
}

.c-title span:last-child {
  font-size: 1.4rem;
}

.p-contact__meta {
  max-width: 1118px;
  margin: 59px auto 268px;
  display: -webkit-box;
  display: flex;
  padding: 0 20px;
}

@media only screen and (max-width: 767px) {
  .p-contact__meta {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 516px) {
  .p-contact__meta {
    margin-bottom: 100px;
  }
}

.p-contact__content {
  width: 54%;
}

@media only screen and (max-width: 767px) {
  .p-contact__content {
    width: 381px;
    margin: 0 auto;
  }
}

.p-contact__center {
  margin-top: 93px;
  max-width: 381px;
  text-align: center;
  width: 100%;
}

.p-contact__center.access {
  text-align: left;
  max-width: 441.1px;
}

.p-contact__text {
  font-size: 1.8rem;
}

@media only screen and (max-width: 516px) {
  .p-contact__text {
    font-size: 1.6rem;
  }
}

.p-contact__phone {
  margin: 65px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-contact__phone span {
  font-size: 3.5rem;
}

.p-contact__phone img {
  margin-right: 18px;
  width: 31.8px;
}

.p-contact__line {
  width: 71.4px;
  margin: 137px auto 24px;
}

.p-contact__info {
  font-size: 2.2rem;
}

.p-contact__qr {
  width: 133.5px;
  margin: 41px auto;
}

.p-contact__image {
  margin-top: 57px;
  width: 50%;
  position: relative;
  height: 100vh;
  background-image: url(../img/bg-contact.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.p-contact__image.access {
  background-image: url(../img/bg-access.jpg);
}

@media only screen and (max-width: 767px) {
  .p-contact__image {
    width: 100%;
    height: 583px;
  }
}

.p-contact__image img {
  width: 100%;
  height: 100%;
}

.p-access__tel {
  font-size: 2.1rem;
}

.p-access__info {
  margin-top: 70px;
  margin-left: 36px;
  line-height: 1.7;
}

@media only screen and (max-width: 516px) {
  .p-access__info {
    margin-left: 0;
  }
}

.p-access__google {
  padding: 0 20px;
  max-width: 1056.5px;
  margin: 0 auto 268px;
}

@media only screen and (max-width: 516px) {
  .p-access__google {
    margin-bottom: 100px;
  }
}

.p-access__meta {
  max-width: 1118px;
  margin: 59px auto 128px;
  display: -webkit-box;
  display: flex;
  padding: 0 20px;
}

@media only screen and (max-width: 767px) {
  .p-access__meta {
    flex-wrap: wrap;
  }
}

.p-system {
  position: relative;
  margin-bottom: 268px;
  margin-top: 44px;
  color: white;
}

@media only screen and (max-width: 767px) {
  .p-system {
    margin-bottom: 100px;
  }
}

.p-system__meta {
  top: 0;
  max-width: 1164px;
  height: 100%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
}

.p-system__image {
  position: relative;
  height: 100vh;
  background-image: url(../img/bg-system.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 767px) {
  .p-system__image {
    height: 583px;
  }
}

.p-system__image img {
  width: 100%;
  height: 100%;
}

.p-system__content {
  padding: 0 31px;
  position: absolute;
  height: 525.5px;
  background-image: url(../img/shadow-system.png);
  background-size: cover;
  top: 14px;
  left: 34px;
  max-width: 365px;
  width: 100%;
}

@media only screen and (max-width: 516px) {
  .p-system__content {
    left: 20px;
    line-height: 2;
    height: 583px;
    width: 90%;
    padding: 0;
  }
}

.p-system__price {
  margin-top: 47px;
  line-height: 1.8;
}

.p-concept {
  position: relative;
  margin-bottom: 268px;
  margin-top: 44px;
  color: white;
}

.p-concept__meta {
  top: 0;
  max-width: 1164px;
  height: 100%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
}

.p-concept__bgr {
  padding-top: 28px;
  padding-left: 41%;
}

@media only screen and (max-width: 767px) {
  .p-concept__bgr {
    padding-left: 0;
  }
}

.p-concept__image {
  position: relative;
  height: 475.4px;
  background-image: url(../img/bg-concept.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 713.2px;
}

.p-concept__image img {
  width: 100%;
  height: 100%;
}

.p-concept__content {
  position: absolute;
  top: 14px;
  left: 34px;
  max-width: 424.1px;
  width: 100%;
}

@media only screen and (max-width: 516px) {
  .p-concept__content {
    left: 20px;
    padding-right: 20px;
  }
}

.p-concept__text {
  color: #C6BE77;
  margin-top: 1px;
  line-height: 2.3;
  margin-left: 13px;
}

@media only screen and (max-width: 516px) {
  .p-concept__text {
    padding-right: 20px;
    margin-left: 0;
  }
}

.p-recruit {
  position: relative;
  margin-bottom: 268px;
  margin-top: 60px;
  color: white;
}

@media only screen and (max-width: 516px) {
  .p-recruit {
    margin-bottom: 200px;
  }
}

.p-recruit__meta {
  max-width: 1118px;
  margin: 0 auto;
  padding: 0 20px;
}

.p-recruit__bgr {
  max-width: 1118px;
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
}

.p-recruit__image {
  top: -11px;
  position: absolute;
  height: 475.4px;
  background-image: url(../img/bg-recruit.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 799.7px;
  right: 0;
  width: 100%;
}

.p-recruit__image img {
  width: 100%;
  height: 100%;
}

.p-recruit__text {
  color: #C6BE77;
  margin-top: 1px;
  line-height: 2.3;
  margin-left: 13px;
}

@media only screen and (max-width: 516px) {
  .p-recruit__text {
    margin-left: 0;
  }
}

.p-recruit__list {
  line-height: 2.1;
  display: -webkit-box;
  display: flex;
  max-width: 491.8px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding: 22px 19px 22px 0;
}

@media only screen and (max-width: 516px) {
  .p-recruit__list {
    padding: 22px 0;
    flex-wrap: wrap;
  }
}

.p-recruit__list span:first-child {
  margin-left: 7px;
  width: 217px;
}

@media only screen and (max-width: 516px) {
  .p-recruit__list span:first-child {
    width: 100%;
    margin-left: 0;
  }
}

.p-recruit__list span:last-child {
  width: 60%;
}

@media only screen and (max-width: 516px) {
  .p-recruit__list span:last-child {
    width: 100%;
  }
}

@media only screen and (max-width: 516px) {
  .p-recruit__info {
    width: 100%;
  }
}

.p-recruit__center {
  margin-top: 138px;
  width: 482px;
  text-align: center;
  font-size: 1.6rem;
}

@media only screen and (max-width: 516px) {
  .p-recruit__center {
    width: 100%;
  }
}

.p-recruit__bottom {
  flex-wrap: wrap;
  margin-top: 70px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-left: 13px;
}

@media only screen and (max-width: 516px) {
  .p-recruit__bottom {
    margin-left: 0;
  }
}

@media only screen and (max-width: 516px) {
  .p-recruit__time {
    display: none;
  }
}

.p-recruit__time.sp {
  display: none;
}

@media only screen and (max-width: 516px) {
  .p-recruit__time.sp {
    display: block;
    font-size: 1.4rem;
  }
}

.p-recruit__desc {
  width: 131.5px;
  height: 38px;
  border: 1px solid;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin: 32px auto 24px;
}

.p-recruit__phone {
  line-height: 1.3;
  font-size: 3.5rem;
}

@media only screen and (max-width: 516px) {
  .p-recruit__phone {
    font-size: 2.5rem;
  }
}

.p-recruit__line {
  width: 50.7px;
  margin: 33px auto 22px;
}

.p-recruit__id {
  font-size: 2.8rem;
  margin-bottom: 27px;
}

@media only screen and (max-width: 516px) {
  .p-recruit__id {
    font-size: 2rem;
  }
}

.p-recruit__qr {
  width: 97.8px;
  margin: 0 auto;
}

.c-yellow {
  color: #C6BE77;
}

.c-yellow.big {
  font-size: 1.674rem;
}

.p-news {
  margin-bottom: 268px;
  margin-top: 60px;
  color: white;
}

@media only screen and (max-width: 516px) {
  .p-news {
    margin-bottom: 200px;
  }
}

.p-news__meta {
  max-width: 1118px;
  margin: 0 auto;
  padding: 0 20px;
}

.p-news__list {
  margin-top: 78px;
  max-width: 1129px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-left: calc(var(--margin) * -1);
  flex-wrap: wrap;
}

.p-news__item {
  width: calc(100% / 4 - var(--margin));
  margin-left: var(--margin);
  margin-bottom: 52px;
}

@media only screen and (max-width: 767px) {
  .p-news__item {
    width: calc(100% / 2 - var(--margin));
  }
}

.p-news__date {
  margin-top: 11px;
  margin-left: 1px;
  font-size: 1.3rem;
}

.p-news__date span:last-child {
  display: inline-block;
  color: #C6BE77;
  margin-left: 17px;
}

.p-news__title {
  margin-left: 1px;
  font-size: 1.6rem;
  margin-top: 2px;
}

:root {
  --margin: 98px;
}

@media only screen and (max-width: 1024px) {
  :root {
    --margin: 30px;
  }
}

@media only screen and (max-width: 767px) {
  :root {
    --margin: 20px;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Utility .u-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
