//============================================
// clearfix
//============================================
@mixin clear {
	*zoom: 1;
	&:after{
		display: block;
		content: "";
		clear: both;
	}
}

//============================================
// breakpoint
//============================================
@mixin breakpoint($point) {
	@if $point == tab {
		@media only screen and (max-width: 1024px) { @content ; }
	}
	@else if $point == sp {
		@media only screen and (max-width: 767px) { @content ; }
	}
	@else if $point == sm {
		@media only screen and (max-width: 516px) { @content ; }
	}
}