/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/

.p-about {
    position: relative;
    margin-bottom: 268px;
    margin-top: 44px;
    color: white;
    @media only screen and (max-width: 767px) {
        margin-bottom: 100px;
    }
    &__bgr {
        padding-left: 28%;
    }
    &__meta {
        top: 0;
        max-width: 1118px;
        height: 100%;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 100%;
    }
    &__image {
        position: relative;
        height: 100vh;
        background-image: url(../img/bg-about.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        @media only screen and (max-width: 767px) {
            height: 583px;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__content {
        position: absolute;
        height: 100vh;
        background-image: url(../img/shadow-about.png);
        background-size: cover;
        top: 14px;
        left: 34px;
        max-width: 485px;
        width: 100%;
        @media only screen and (max-width: 516px) {
            left: 20px;
            line-height: 2;
            height: 583px;
            width: 90%;
        }
    }
    &__text {
        margin-top: 46px;
    }
    &__info {
        display: flex;
        margin-bottom: 38px;
        span:first-child {
            width: 107px;
            display: inline-block;
        }
        span:last-child {
            @media only screen and (max-width: 516px) {
                width: 60%;
            }
        }
    }
    &__sp {
        display: none;
        @media only screen and (max-width: 516px) {
            display: block;
        }
    }
    &__pc {
        @media only screen and (max-width: 516px) {
            display: none;
        }
    }
}

.c-title {
    span:first-child {
        font-family: 'Tangerine', cursive;
        font-size: 8.2rem;
        font-style: italic;
        color: #C6BE77;
        margin-right: 35px;
        font-weight: 700;
        line-height: 1;
        @media only screen and (max-width: 767px) {
            font-size: 6.2rem;
        }
        @media only screen and (max-width: 516px) {
            font-size: 5.2rem;
            display: block;
            margin-right: 0;
        }
    }
    span:last-child {
        font-size: 1.4rem;
    }
}

.p {
    &-contact {}
    &-contact__meta {
        max-width: 1118px;
        margin: 59px auto 268px;
        display: -webkit-box;
        display: flex;
        padding: 0 20px;
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
        @media only screen and (max-width: 516px) {
            margin-bottom: 100px;
        }
    }
    &-contact__content {
        width: 54%;
        @media only screen and (max-width: 767px) {
            width: 381px;
            margin: 0 auto;
        }
    }
    &-contact__center {
        margin-top: 93px;
        max-width: 381px;
        text-align: center;
        width: 100%;
        &.access {
            text-align: left;
            max-width: 441.1px;
        }
    }
    &-contact__text {
        font-size: 1.8rem;
        @media only screen and (max-width: 516px) {
            font-size: 1.6rem;
        }
    }
    &-contact__phone {
        margin: 65px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            font-size: 3.5rem;
        }
        img {
            margin-right: 18px;
            width: 31.8px;
        }
    }
    &-contact__line {
        width: 71.4px;
        margin: 137px auto 24px;
    }
    &-contact__info {
        font-size: 2.2rem;
    }
    &-contact__qr {
        width: 133.5px;
        margin: 41px auto;
    }
    &-contact__image {
        margin-top: 57px;
        width: 50%;
        position: relative;
        height: 100vh;
        background-image: url(../img/bg-contact.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        &.access {
            background-image: url(../img/bg-access.jpg);
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            height: 583px;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
}

.p-access__tel {
    font-size: 2.1rem;
}

.p-access__info {
    margin-top: 70px;
    margin-left: 36px;
    line-height: 1.7;
    @media only screen and (max-width: 516px) {
        margin-left: 0;
    }
}

.p-access__google {
    padding: 0 20px;
    max-width: 1056.5px;
    margin: 0 auto 268px;
    @media only screen and (max-width: 516px) {
        margin-bottom: 100px;
    }
}

.p-access__meta {
    max-width: 1118px;
    margin: 59px auto 128px;
    display: -webkit-box;
    display: flex;
    padding: 0 20px;
    @media only screen and (max-width: 767px) {
        flex-wrap: wrap;
    }
}

.p-system {
    position: relative;
    margin-bottom: 268px;
    margin-top: 44px;
    color: white;
    @media only screen and (max-width: 767px) {
        margin-bottom: 100px;
    }
    &__meta {
        top: 0;
        max-width: 1164px;
        height: 100%;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 100%;
    }
    &__image {
        position: relative;
        height: 100vh;
        background-image: url(../img/bg-system.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        @media only screen and (max-width: 767px) {
            height: 583px;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__content {
        padding: 0 31px;
        position: absolute;
        height: 525.5px;
        background-image: url(../img/shadow-system.png);
        background-size: cover;
        top: 14px;
        left: 34px;
        max-width: 365px;
        width: 100%;
        @media only screen and (max-width: 516px) {
            left: 20px;
            line-height: 2;
            height: 583px;
            width: 90%;
            padding: 0;
        }
    }
    &__price {
        margin-top: 47px;
        line-height: 1.8;
    }
}

.p-concept {
    position: relative;
    margin-bottom: 268px;
    margin-top: 44px;
    color: white;
    &__meta {
        top: 0;
        max-width: 1164px;
        height: 100%;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 100%;
    }
    &__bgr {
        padding-top: 28px;
        padding-left: 41%;
        @media only screen and (max-width: 767px) {
            padding-left: 0;
        }
    }
    &__image {
        position: relative;
        height: 475.4px;
        background-image: url(../img/bg-concept.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        max-width: 713.2px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__content {
        position: absolute;
        top: 14px;
        left: 34px;
        max-width: 424.1px;
        width: 100%;
        @media only screen and (max-width: 516px) {
            left: 20px;
            padding-right: 20px;
        }
    }
    &__text {
        color: #C6BE77;
        margin-top: 1px;
        line-height: 2.3;
        margin-left: 13px;
        @media only screen and (max-width: 516px) {
            padding-right: 20px;
            margin-left: 0;
        }
    }
}

.p-recruit {
    position: relative;
    margin-bottom: 268px;
    margin-top: 60px;
    color: white;
    @media only screen and (max-width: 516px) {
        margin-bottom: 200px;
    }
    &__meta {
        max-width: 1118px;
        margin: 0 auto;
        padding: 0 20px;
    }
    &__bgr {
        max-width: 1118px;
        margin: 0 auto;
        position: relative;
        padding: 0 20px;
    }
    &__image {
        top: -11px;
        position: absolute;
        height: 475.4px;
        background-image: url(../img/bg-recruit.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        max-width: 799.7px;
        right: 0;
        width: 100%;
        img {
            width: 100%;
            height: 100%;
        }
    }
    &__content {}
    &__text {
        color: #C6BE77;
        margin-top: 1px;
        line-height: 2.3;
        margin-left: 13px;
        @media only screen and (max-width: 516px) {
            margin-left: 0;
        }
    }
    &__list {
        line-height: 2.1;
        display: -webkit-box;
        display: flex;
        max-width: 491.8px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        padding: 22px 19px 22px 0;
        @media only screen and (max-width: 516px) {
            padding: 22px 0;
            flex-wrap: wrap;
        }
        span:first-child {
            margin-left: 7px;
            width: 217px;
            @media only screen and (max-width: 516px) {
                width: 100%;
                margin-left: 0;
            }
        }
        span:last-child {
            width: 60%;
            @media only screen and (max-width: 516px) {
                width: 100%;
            }
        }
    }
    &__info {
        @media only screen and (max-width: 516px) {
            width: 100%;
        }
    }
    &__center {
        margin-top: 138px;
        width: 482px;
        text-align: center;
        font-size: 1.6rem;
        @media only screen and (max-width: 516px) {
            width: 100%;
        }
    }
    &__bottom {
        flex-wrap: wrap;
        margin-top: 70px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-left: 13px;
        @media only screen and (max-width: 516px) {
            margin-left: 0;
        }
    }
    &__time {
        @media only screen and (max-width: 516px) {
            display: none;
        }
        &.sp {
            display: none;
            @media only screen and (max-width: 516px) {
                display: block;
                font-size: 1.4rem;
            }
        }
    }
    &__desc {
        width: 131.5px;
        height: 38px;
        border: 1px solid;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        margin: 32px auto 24px;
    }
    &__phone {
        line-height: 1.3;
        font-size: 3.5rem;
        @media only screen and (max-width: 516px) {
            font-size: 2.5rem;
        }
    }
    &__line {
        width: 50.7px;
        margin: 33px auto 22px;
    }
    &__id {
        font-size: 2.8rem;
        margin-bottom: 27px;
        @media only screen and (max-width: 516px) {
            font-size: 2rem;
        }
    }
    &__qr {
        width: 97.8px;
        margin: 0 auto;
    }
}

.c-yellow {
    color: #C6BE77;
    &.big {
        font-size: 1.674rem;
    }
}

.p {
    &-news {
        margin-bottom: 268px;
        margin-top: 60px;
        color: white;
        @media only screen and (max-width: 516px) {
            margin-bottom: 200px;
        }
    }
    &-news__meta {
        max-width: 1118px;
        margin: 0 auto;
        padding: 0 20px;
    }
    &-news__content {}
    &-news__list {
        margin-top: 78px;
        max-width: 1129px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-left: calc(var(--margin) * -1);
        flex-wrap: wrap;
    }
    &-news__item {
        width: calc(100% / 4 - var(--margin));
        margin-left: var(--margin);
        margin-bottom: 52px;
        @media only screen and (max-width: 767px) {
            width: calc(100% / 2 - var(--margin));
        }
    }
    &-news__image {}
    &-news__date {
        margin-top: 11px;
        margin-left: 1px;
        font-size: 1.3rem;
        span:first-child {}
        span:last-child {
            display: inline-block;
            color: #C6BE77;
            margin-left: 17px;
        }
    }
    &-news__title {
        margin-left: 1px;
        font-size: 1.6rem;
        margin-top: 2px;
    }
}

:root {
    --margin: 98px;
    @media only screen and (max-width: 1024px) {
        --margin: 30px;
    }
    @media only screen and (max-width: 767px) {
        --margin: 20px;
    }
}