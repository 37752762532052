/* Header
------------------------------------------------------------*/

.c-header {}


/* Main menu
------------------------------------------------------------*/

.c {
    &-header {
        color: white;
        li {
            font-weight: 600;
        }
    }
    &-header__list {
        padding: 0 20px;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin: 19px auto;
        max-width: 1118px;
        @media only screen and (max-width: 1024px) {
            align-items: center;
        }
    }
    &-header__info {
        @media only screen and (max-width: 1024px) {
            display: none;
        }
        h2 {
            font-size: 2.8rem;
        }
        span {
            font-size: 1.5rem;
            p {
                font-size: 2.3rem;
            }
        }
    }
    &-header__logo {
        margin-top: 20px;
        width: 102px;
        @media only screen and (max-width: 1024px) {
            margin-top: 0;
        }
    }
    &-header__recruit {
        width: 248px;
        margin-top: 30px;
        @media only screen and (max-width: 1024px) {
            margin-top: 0;
        }
        @media only screen and (max-width: 516px) {
            display: none;
        }
    }
    &-header__menu {
        padding: 0 20px;
        max-width: 947px;
        margin: 35px auto 15px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        @media only screen and (max-width: 1024px) {
            display: none;
        }
        li {
            padding: 0 3px;
            position: relative;
        }
        span:after {
            z-index: 999;
            content: "";
            position: absolute;
            top: 140%;
            left: 0;
            width: 100%;
            height: 7px;
            background: #DAB975;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transition: -webkit-transform 0.3s ease;
            transition: -webkit-transform 0.3s ease;
            transition: transform 0.3s ease;
            transition: transform 0.3s ease, -webkit-transform 0.3s ease;
        }
        li:hover span:after {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
    &-header__border {
        height: 1px;
        width: 100%;
        background-color: #DAB975;
    }
}

.header-menu__link {
    position: absolute;
    width: 70%;
    top: 20%;
    left: 43%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    li {
        margin-bottom: 30px;
        font-size: 2rem;
        color: white;
    }
}