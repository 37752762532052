@charset 'utf-8';
/*------------------------------------------------------------
	Reset
------------------------------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
nav, section,
time, mark, audio, video {
	// font-size: 1em;
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	box-sizing: border-box;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
	display: block;
}

html {
	font-size: 62.5%;
	box-sizing: border-box;
}

body{
	-webkit-text-size-adjust: 100%;
}

body, table,
input, textarea, select, option,
h1, h2, h3, h4, h5, h6 {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: bold;
}

table,
input, textarea, select, option {
	line-height: 1.1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

:focus {
	outline: 0;
}

ins {
	text-decoration: none;
}

del {
	text-decoration: line-through;
}

img {
	vertical-align: top;
}

a,
a img{
	transition: 0.3s ease-in-out;
}
a{
	color: inherit;
	text-decoration: none;
}
a:hover,
a:hover img{
    opacity: 0.80;
    filter: alpha(opacity=80);
	-ms-filter: "alpha(opacity=80)";
}
img{
	max-width: 100%;
}